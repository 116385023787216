<template>
<div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">                     
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false">
                           <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                             <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-digital-marketing"></i> {{title_text}}</h3>
                           <el-row>
                           
                              <el-col :span="8"  >
                                 <el-form-item label="Customer Name" prop="customer_id">                                  
                                    <el-select ref="customer_name" v-model="form.customer_id" :disabled="this.$route.params.order_id>0" @change="getCustomerOrder">
                                       <el-option v-for="(customer,index) in customer_list" :key="index" :label="customer.first_name+' '+customer.last_name" :value="customer.id" style="height:auto;padding: 10px 20px;">
                                       </el-option>                                      
                                    </el-select>                                   
                                    
                                 </el-form-item>
                              </el-col>    
                              <el-col :span="6" :offset="1">
                                 <el-form-item label="Order Number" prop="order_number">
                                    <el-select clearable filterable v-model="form.order_id"  style="width:100%" :allow-create="true" :disabled="this.$route.params.order_id>0" @change="getOrderItems">                            
                                       <el-option v-for="order in order_list" :key="order.id" :label="order.order_number" :value="order.id" ></el-option>
                                    </el-select>                                    
                                 </el-form-item>
                              </el-col>                    
                              <el-col :span="6" :offset="1">
                                 <el-form-item label="Date" prop="ship_date" style="margin-bottom:0px;"></el-form-item>
                                 <el-date-picker  v-model="form.ship_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;"  value-format="dd MMM yyyy" disabled> </el-date-picker>
                                 <span class="form-err" v-if="form.errors.has('ship_date')" >{{form.errors.get('ship_date')}}</span>
                              </el-col>                    
                              
                           </el-row >
                           <el-row style="margin-top:20px;">
                               <el-col :span="12">
                                <div class="billing-adr" >Billing Address :</div>
                                <el-form-item >
                                  <el-row>
                                    <el-col :span="24" v-for="billing in billing_address" :key="billing.id" style="padding-left:5px;">                                     
                                      <div>{{billing.address_line_1+', '+billing.address_line_2}}</div>
                                      <div>{{billing.city_details?billing.city_details.city_name:''}}</div>
                                      <div>{{billing.state_details?billing.state_details.state_name:''}}- {{billing.post_code}}</div>                                      
                                    </el-col>
                                  </el-row>                                  
                                <span class="form-err" v-if="form.errors.has('billing_address_id')" >{{form.errors.get('billing_address_id')}}</span>
                               </el-form-item>    
                              </el-col>
                           
                              <el-col :span="1">
                                 
                              </el-col>
                               <el-col :span="11" :offset="1">
                                 <div class="billing-adr" >Shipping Address :</div>
                                 <el-form-item prop="shipping_address_id">
                                    <el-row>
                                    <el-col :span="24" v-for="shipping in shipping_address" :key="shipping.id" style="padding-left:5px;">                                     
                                      <div>{{shipping.address_line_1+', '+shipping.address_line_2}}</div>
                                      <div>{{shipping.city_details?shipping.city_details.city_name:''}}</div>
                                      <div>{{shipping.state_details?shipping.state_details.state_name:''}}- {{shipping.post_code}} <span style="margin-left:20px;"><el-tag size="mini"  v-on:click="ship = !ship" style="cursor:pointer">Change</el-tag></span></div>                                     
                                    </el-col>
                                  </el-row>
                                    <el-select v-if="ship" clearable filterable v-model="form.shipping_address_id" placeholder="Select Address"  :disabled="!form.customer_id" :allow-create="true" style="width:100%" @change="addShipping($event)" auto-complete="nope">
                                      <el-option class="add-container" value="">
                                          <a class="add-drop-btn" @click="createAddress(2)">
                                            <i class="el-icon-circle-plus"></i>
                                            Add Address
                                          </a>
                                      </el-option>   
                                       <el-option v-for="address in shippingAddresses" :key="address.id" :label="addressLabel(address)" :value="address.id" ></el-option>
                                    </el-select>
                                    <span class="form-err" v-if="form.errors.has('shipping_address_id')" >{{form.errors.get('shipping_address_id')}}</span>
                                 </el-form-item>
                              </el-col>
                           
                              <el-col :span="1">
                                 
                              </el-col>
                           </el-row>
                             <div class="row">
                              <div class="col-xl-12"><h4>Order Items</h4>                                
                                
                              </div>
                           </div>
                            <el-form-item  v-if="form.order_id" prop="products">
                             
                              <el-table :data="form.products" stripe border fit >                                                         
                                  <el-table-column
                                    type="index"
                                    width="50">                                    
                                  </el-table-column>
                                  <el-table-column label="Item #" width="150">
                                       <template slot-scope="scope">
                                          <el-input v-model="form.products[scope.$index].product.sku" @input.native="capitalize"  controls-position="right"></el-input>
                                         
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Product Name" >                                     
                                       <template slot-scope="scope">
                                         <el-input v-model="form.products[scope.$index].product.name" @input.native="capitalize"  controls-position="right" ></el-input>
                                      </template>
                                  </el-table-column>
                                   <el-table-column label="Ship Via" width="100">
                                     <template >
                                          <el-input v-model="form.ship_via" @input.native="capitalize"  controls-position="right" ></el-input>
                                      </template>
                                  </el-table-column>                                  
                                   <el-table-column label="Type" width="100">
                                      <template >
                                          <el-input v-model="form.type" @input.native="capitalize"  controls-position="right" :min="0" :step="1" ></el-input>
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Quantity" width="100">
                                      <template slot-scope="scope">
                                          <el-input v-model="form.products[scope.$index].back_order" placeholder="0" controls-position="right" :disabled="true" class="disabled_input" :value="10" ></el-input>
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Ship Quantity" width="150">
                                     <template slot-scope="scope">
                                          <el-input-number v-model="form.products[scope.$index].shipped" placeholder="0" :min="0" :step="1"  :max="form.products[scope.$index].back_order"  @change="setProductWeight(scope.$index)"></el-input-number>
                                      </template>                            
                                  </el-table-column>                              

                                  <el-table-column label="Weight" width="100">
                                      <template slot-scope="scope">
                                          <el-input v-model="form.products[scope.$index].net_weight" placeholder="0" controls-position="right"></el-input>
                                      </template>
                                  </el-table-column>
                                  
                                </el-table>
                             
                           </el-form-item>
                           <el-row>
                              <el-col :span="15">
                                 <el-form-item label="Other Comments or Special Instructions" prop="comments">
                                   <el-input type="textarea" :rows="4"  v-model="form.comments" @input.native="capitalize"></el-input>
                                </el-form-item>  
                                </el-col>
                             <el-col :span="8" :offset="1">
                                 <el-form-item label="Total Quantity" prop="total_quantity">
                                   <el-input v-model="form.total_quantity" disabled></el-input>                                
                                 </el-form-item> 
                                  <el-form-item label="Total Weight (Kg)" prop="Shipped Total">
                                   <el-input v-model="form.total_weight" disabled></el-input>                                
                                 </el-form-item>                                                            
                              </el-col>                             
                           </el-row>
                             <el-form-item style="margin-top:25px;">
                                  <el-button type="primary" @click="createPackingslip">{{submit_btn_text}}</el-button>                                 
                              </el-form-item>     
                            </div>
                        </el-form>                     
                    </div>
                </div>
            </div>
        </div>
</div>
  
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getAllCustomers} from "@/api/customer";
import {getCustomerOrder, getOrderList} from "@/api/packingslip";
import {searchCustomer,getCustomerAddress} from "@/api/order";
import { getProductsForDropdown} from "@/api/product";
import CreateAddress from '@/components/CreateAddress/addAddress';
export default {
   name: 'add-PackingSlip',
    data() {      
    return {
      loading:false,     
      error: null,
      bill : false,
      ship : false,
      defaultDate: new Date('2018-06-22'),
       form : new Form({
         customer_id: null,
         order_id: null,
         ship_date: new Date(),
         billing_address_id: null,
         shipping_address_id : null,
         total_weight:0,
         total_quantity:0,
         ship_via:null,
         type:null,
         comments: null,
         products: [
           {
             product_id: null,                       
             quantity: null,            
             product_weight:null,            
             shipped: 0,
             net_weight:0,
             back_order:null,
             product_item: [{
               sku: null,
               name:null
             }]            
           }
         ]
         
       }),      
       submit_btn_text: 'Create Packing Slip',
       title_text: 'Packing Slip',     
       customer_list:[],
       order_list:[],      
       addresses:[],
       product_list: [],
       billing_address:[],
       shipping_address:[],
       api_url : 'packingslip/create'
       
    }
    },
     mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Packing Slip", route: "/packingslip/list" },
      { title: "Add/Edit Packing Slip" }
    ]);
    globalEvents.$on('AddressAdded', added => { 
      this.shipping_address = [];        
        if (added) {     
          this.addresses.push(added);         
          if(added.type == 'billing'){
            this.form.billing_address_id = added.id
            this.billing_address.push(added);
            this.bill = false;
          }else{
            this.form.shipping_address_id = added.id
            this.shipping_address.push(added);
            this.ship = false;
          }
        }        
      });
     },
     methods: {
       isExist(product_id){
      for(var i=0; i < this.form.products.length; i++){
        if( this.form.products[i].product_id == product_id){
          return true
        }
      }
      return false
    },

      getAllCustomers(){
      this.loading = true
      getAllCustomers().then(response => {        
        this.customer_list = response.data.data.data
        this.loading = false
       
      })   
    },
    getCustomerOrder(){      
       this.loading = true        
      getCustomerOrder(this.form.customer_id).then(response => {    
        this.order_list = response.data.data;      
        this.loading = false
       
      })  
       getCustomerAddress(this.form.customer_id).then(response => {                   
            this.loading = false
            this.addresses = response.data.data;
          }); 
    },
   
    getOrderItems(){
       this.loading = true;
       this.billing_address = [];
       this.shipping_address = [];
        this.form.products = [];
      getOrderList(this.form.order_id).then(response => {        
       this.form.billing_address_id = response.data.data.billing_address_order.id; 
       this.form.shipping_address_id = response.data.data.shipping_address_order.id;
       this.form.ship_via = response.data.data.ship_via;
       this.form.type = response.data.data.type;
       this.form.products = response.data.data.order_items;
        this.billing_address.push(response.data.data.billing_address_order);
       this.shipping_address.push(response.data.data.shipping_address_order);         
       this.loading = false
      })   

    },
     getAllproductlists(){
      this.loading = true
      getProductsForDropdown().then(response => {
        this.product_list = response.data.data       
        this.loading = false
      })
    },   
   createAddress(val){      
      var titleName = 'Shipping Address';
      if(val == '1'){
        titleName = 'Billing Address'
      }
     this.$modal.show(CreateAddress, {
          customer_id: this.form.customer_id,
          address_type : val,         
          label:{button:'Create',title:titleName}
        }, 
        { draggable: true, transition: 'pop-out', overflow:'auto', height: 'auto', clickToClose: false, adaptive: true, width: '600px'})
    },
   
    addressLabel(address){
      let formattedAddress = '';
      if(address.company_name){
        formattedAddress += address.company_name + ', ';
      }
      if(address.address_line_1){
        formattedAddress += address.address_line_1 + ', ';
      }
      if(address.address_line_2){
        formattedAddress += address.address_line_2 + ', ';
      }
      if(address.city){
        formattedAddress += address.city_details.city_name + ', ';
      }
      if(address.state){
        formattedAddress += address.state_details.state_name + ', ';
      }
      if(address.post_code){
        formattedAddress += address.post_code + ', ';
      }
      return formattedAddress;
    },
    setProductWeight(index){
       let pid = this.form.products[index].product_id;              
       let shipping = parseInt(this.form.products[index].shipped);
      this.form.products[index].product_weight = shipping*this.getProductWeight(pid);    
      this.form.products[index].net_weight = this.getProductWeight(pid);      
      this.calculateTotal();
    },
     getProductWeight(product_id){
      let productWeight = 0;
      if(product_id){
        this.product_list.map((product) =>{
          
          if(product_id == product.id){
            productWeight = product.weight;
          }
        })
      }     
      return productWeight;      
    },
    calculateTotal() {
      
        var quantity_total = this.form.products.reduce(function (sum, product) {             
                var quantity = parseFloat(product.shipped);
                if (!isNaN(quantity)) {                 
                    return sum + quantity;
                }else{                    
                  return sum;
                }
            }, 0);
        
          var weightTotal = this.form.products.reduce(function (sum, product) {             
                var ProductTotal = parseFloat(product.product_weight);
                if (!isNaN(ProductTotal)) {                 
                    return sum + ProductTotal;
                }else{                    
                  return sum;
                }
            }, 0);
             this.form.total_weight = weightTotal; 
             this.form.total_quantity = quantity_total;     
      },
       createPackingslip: function() {                
      var event = new Date(this.form.ship_date);
      let date = JSON.stringify(event);
      this.form.ship_date = date.slice(1,11);
      this.form.post(this.api_url).then((response) => {
        if(response.status){
            this.$router.push({ path: '/customer/view/'+ this.$route.params.customer_id });
            this.loading=false;
            this.$showResponse('success', response.message);
        }
      }).catch((error) => {
				  this.loading = false;
	        	console.log(error)
	      	})
    }, 
     addShipping(){
      let shipping_id = this.form.shipping_address_id;
      this.shipping_address = [];
      this.addresses.map((billing)=>{
        if(billing.type == 'shipping'){
          if(shipping_id == billing.id){
            this.shipping_address.push(billing);
            this.ship = false;
          }
        }
      });
    }
     
     },
      watch: { 
      search(value) {
        if (!value) {
          return
        }         
        this.loading = true      
        searchCustomer(value).then(response => {        
          this.customer_list = response.data.data         
          this.loading = false
        })
      },  
     
  },
     created(){
       let orderId = this.$route.params.order_id;       
   
    if(parseInt(orderId) > 0){ 
          
       getOrderList(orderId).then(response => {  
        this.form.customer_id = response.data.data.customer_id;     
        this.form.order_id = response.data.data.id;
        this.form.billing_address_id = response.data.data.billing_address_order.id;  
       this.form.shipping_address_id = response.data.data.shipping_address_order.id;
       this.form.ship_via = response.data.data.ship_via;
       this.form.type = response.data.data.type;
       this.form.products = response.data.data.order_items; 
       this.billing_address.push(response.data.data.billing_address_order);
       this.shipping_address.push(response.data.data.shipping_address_order);      
      this.getCustomerOrder();  
      
      });
    }       
       this.getAllCustomers();
       this.getAllproductlists();
      
     },
      computed :{
      shippingAddresses() {
          return this.addresses.filter(addr => {
            return addr.type == 'shipping'
          });
      },
     
  },


  
}
</script>

<style >

  .billing-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }

</style>